import { useCallback, useEffect, useState } from "react";
import { acceptCookieNotice, getNoticeAcceptance } from "../services/cookie";
import { grantAllConsent } from "../services/analytics";

const useCookieNotice = () => {
    const [showCookieNotice, setShowCookieNotice] = useState(false);
  
    const handleAcceptCookies = useCallback(() => {
      acceptCookieNotice();
      grantAllConsent();
      
      setShowCookieNotice(false);
    }, []);

    useEffect(() => {
        const isCookieAccepted = getNoticeAcceptance();
        if (!isCookieAccepted) setShowCookieNotice(true);
    }, []);

    return { handleAcceptCookies, showCookieNotice };
};
 
export default useCookieNotice;