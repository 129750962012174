import React from 'react';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useMutation } from '@tanstack/react-query';
import { BiInfoCircle } from 'react-icons/bi';

import { saveFeedback } from '../../services/chat';

import classNames from './style.module.css';

const schema = z.object({
    subject: z.string().min(3, 'Subject must be at least 3 characters'),
    message: z.string().min(10, 'Message must be at least 10 characters')
});

const Feedback = () => {
    const { t } = useTranslation();
    const { register, formState, handleSubmit, reset } = useForm({
        resolver: zodResolver(schema),
        mode: 'all'
    });

    const mutation = useMutation({ 
        mutationFn: saveFeedback,
    });

    const onSaveFeedback = async (feedback) => {
        mutation.mutate(feedback);
        reset();
    };

    return ( 
        <form className={classNames.feedbackForm} onSubmit={handleSubmit(onSaveFeedback)}>
            {mutation.isError && (
                <div className={classNames.feedbackFormError}>
                    <BiInfoCircle  />
                    <div className={classNames.feedbackFormText}>Cannot not save your feedback at the moment. Please try again later</div>
                </div>
            )}

            {mutation.isSuccess && (
                <div className={classNames.feedbackFormSuccess}>
                    <BiInfoCircle  />
                    <div className={classNames.feedbackFormText}>Feedback saved successfully!</div>
                </div>
            )}

            <div className={classNames.feedbackFormInputContainer}>
                <label htmlFor="subject" className={classNames.feedbackFormLabel}>{t('ChatBotFeedback.LeaveFeedback')}</label>
                <input 
                    type="text" 
                    className={classNames.feedbackFormInput} 
                    placeholder={t('ChatBotFeedback.EnterSuggestion')}
                    {...register('subject')} 
                />
                {formState.errors.subject && <div className={classNames.feedbackFormErrorText}>{formState.errors.subject.message}</div>}
            </div>
            
            <div className={classNames.feedbackFormInputContainer}>
                <label htmlFor="message" className={classNames.feedbackFormLabel}>{t('ChatBotFeedback.LeaveMoreDetails')}</label>
                <input 
                    type="text" 
                    className={classNames.feedbackFormInput} 
                    placeholder={t('ChatBotFeedback.EnterDetailsHere')}
                    {...register('message')} 
                />
                {formState.errors.message && <div className={classNames.feedbackFormErrorText}>{formState.errors.message.message}</div>}
            </div>

            <button disabled={mutation.isPending} className={classNames.feedbackFormButton} type='submit'>
                {mutation.isPending ? <div className={classNames.loader}></div> : t('ChatBotFeedback.Submit')}
            </button>
        </form>
    );
};
 
export default Feedback;