import Cookies from 'js-cookie';

const KEYS = {
    NOTICE: 'cap-cookie-notice',
    CHAT: 'cap-chat-id',
};

export const acceptCookieNotice = () => {
    Cookies.set(KEYS.NOTICE, true, { expires: 3, path: '/' });
};

export const getNoticeAcceptance = () => {
    return Cookies.get(KEYS.NOTICE);
};

export const rejectCookieNotice = () => {
    Cookies.remove(KEYS.NOTICE, { path: '/' });
};

export const storeChatId = (chatId) => {
    Cookies.set(KEYS.CHAT, chatId, { expires: 3, path: '/' });
};

export const getChatId = () => {
    return Cookies.get(KEYS.CHAT);
};
