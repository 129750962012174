import dayjs from 'dayjs';
import { MdHome } from 'react-icons/md';
import { HiChatAlt2 } from 'react-icons/hi';

export const HELP_SECTIONS = [
    {
        title: 'Managing your account',
        body: 'Learn more about managing your account.',
        route: '/'
    },
    {
        title: 'Managing your account 2',
        body: 'Learn more about managing your account.',
        route: '/'
    },
    {
        title: 'Managing your account 3',
        body: 'Learn more about managing your account.',
        route: '/'
    },
    {
        title: 'Managing your account 4',
        body: 'Learn more about managing your account.',
        route: '/'
    },
    {
        title: 'Managing your account 5',
        body: 'Learn more about managing your account.',
        route: '/'
    },
    {
        title: 'Managing your account 6',
        body: 'Learn more about managing your account.',
        route: '/'
    },
    {
        title: 'Managing your account 7',
        body: 'Learn more about managing your account.',
        route: '/'
    },
];

export const ENQUIRY_TAGS = [
    {
        label: 'General Enquiry',
        command: 'I have a general enquiry'
    },
    {
        label: 'Store Locations',
        command: 'I want to know about CAP\'s store locations'
    },
    {
        label: 'Become a partner',
        command: 'How do i become a partner'
    },
    {
        label: 'Investor relations',
        command: 'Tell me about investor relations'
    },
];

export const TAB_CATEGORIES = {
    HOME: 'home',
    MESSAGES: 'messages',
};

export const TABS = [
    {
        key: TAB_CATEGORIES.HOME,
        Icon: MdHome,
        label: 'Home'
    },
    {
        key: TAB_CATEGORIES.MESSAGES,
        Icon: HiChatAlt2,
        label: 'Messages'
    },
];

export const INITIAL_CHAT_DETAILS = {
    content: [{ text: { value: 'Welcome to the CAP Chat Assistant. Let us know how we can be of help' }}],
    created_at: dayjs().unix(),
    id: "1",
    role: 'assistant'
};

export const DB_SECTIONS = [
    {
        title: 'How to locate any of our stores',
        body: 'All you need to know about locating any of our stores nationwide',
    },
]

export const DISPLAY_HELP_SECTION = false;