import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import capLogoLight from "../../assets/logos/cap_light.svg";
import capLogoDark from "../../assets/logos/cap_dark.svg";
import "../../styles/nav.css";

import { MdOutlineColorLens } from "react-icons/md";
import { BsQuestionOctagon } from "react-icons/bs";
import { HiOutlineBriefcase } from "react-icons/hi2";
import { CgScreen } from "react-icons/cg";
import { SlGlobe } from "react-icons/sl";
import { AiOutlineHistory, AiOutlineClockCircle } from "react-icons/ai";
import { FaTrademark, FaBars, FaTimes, FaChevronDown } from "react-icons/fa";
import {
  LuUsers,
  LuLocateFixed,
  LuTags,
  LuMonitorPause,
  LuGlobe,
} from "react-icons/lu";
import {
  TbUserStar,
  TbFunction,
  TbPaint,
  TbRainbow,
  TbCalculator,
  TbTimelineEvent,
  TbHistoryToggle,
  TbUserQuestion,
  TbStack3,
  TbBuildingEstate,
  TbGavel,
  TbSpeakerphone,
  TbChecklist,
  TbHeartHandshake,
  TbClipboardData,
  TbNews,
  TbBorderBottomPlus,
} from "react-icons/tb";
import visualizer from "../../assets/icons/visualizer.svg";
import results from "../../assets/icons/results.svg";
import monitor from "../../assets/icons/monitor.svg";
import globe from "../../assets/icons/globe_equator.svg";
import vacancies from "../../assets/icons/vacancies.svg";
import newspaper from "../../assets/icons/newspaper.svg";
import plus from "../../assets/icons/plus.svg";

import about from "../../assets/images/nav-bgs/about.png";
import bus_segments from "../../assets/images/nav-bgs/bus_segments.png";
import resources from "../../assets/images/nav-bgs/resources.png";
import investors from "../../assets/images/nav-bgs/investors.png";
import careers from "../../assets/images/nav-bgs/careers.png";
import media from "../../assets/images/nav-bgs/media.png";
import contact from "../../assets/images/nav-bgs/contact.png";

import NavPopup from "./nav-popup";

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState({
    title: "",
    links: [],
    imageSrc: "",
    readMoreLink: "",
    position: { top: 0, left: 0 },
  });

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 875);
  const navbarRef = useRef(null);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  useEffect(() => {
    const handleResize = () => setIsMobileScreen(window.innerWidth < 875);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavEvent = (
    event,
    title,
    links,
    imageSrc,
    readMoreLink,
    index
  ) => {
    if (isMobileScreen) {
      setActiveDropdown(activeDropdown === index ? null : index);
    } else {
      const targetRect = event.target.getBoundingClientRect();
      const navbarRect = navbarRef.current.getBoundingClientRect();
      setPopupData({
        title,
        links,
        imageSrc,
        readMoreLink,
        position: { top: targetRect.bottom + 20, left: navbarRect.left + 15 },
      });
      setIsPopupOpen(true);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setActiveDropdown(null);
  };

  const handleNavLinkClick = () => {
    if (isMobileScreen) setMenuOpen(false);
    handleClosePopup();
  };

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const navigate = useNavigate();
  const navigateHome = () => {
    navigate("/");
  };

  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isMediaPage = location.pathname === "/media";

  const aboutLinks = [
    {
      text: "Our History",
      url: "/about/our-history",
      icon: <AiOutlineHistory />,
    },
    {
      text: "CAP Today",
      url: "/about/who-we-are",
      icon: <AiOutlineClockCircle />,
    },
    {
      text: "Leadership Team",
      url: "/about/our-leadership",
      icon: <TbUserStar />,
    },
  ];

  const businessSegmentLinks = [
    {
      text: "Products by Function",
      url: "/business-segments",
      icon: <TbFunction />,
    },
    {
      text: "Our Brands",
      url: "/business-segments#brands",
      icon: <FaTrademark />,
    },
  ];

  const resourcesLinks = [
    {
      text: "Colour Guides & Consultancy",
      url: "/resources#colour-guides-and-consultancy",
      icon: <MdOutlineColorLens />,
    },
    {
      text: "Paint Calculator",
      url: "/resources#paint-calculator",
      icon: <TbCalculator />,
    },
    {
      text: "Visualizer App",
      url: "/resources",
      icon: isMobileScreen ? (
        <LuTags />
      ) : (
        <img src={visualizer} alt="Visualizer App" />
      ),
    },
    {
      text: "Painters’ Academy",
      url: "/resources/painters-academy",
      icon: <TbPaint />,
    },
    {
      text: "Loyalty Scheme",
      url: "/resources#loyalty-scheme",
      icon: <TbRainbow />,
    },
    {
      text: "FAQs",
      url: "/resources/frequently-asked-questions",
      icon: <BsQuestionOctagon />,
    },
  ];

  const investorsLinks = [
    {
      text: "Annual Reports",
      url: "/investors/annual-reports",
      icon: isMobileScreen ? (
        <LuMonitorPause />
      ) : (
        <img src={monitor} alt="monitor" />
      ),
    },
    {
      text: "Financial Results",
      url: "/investors/financial-reports",
      icon: isMobileScreen ? (
        <TbClipboardData />
      ) : (
        <img src={results} alt="results" />
      ),
    },
    {
      text: "Corporate Governance",
      url: "/investors/corporate-governance",
      icon: <TbGavel />,
    },
    {
      text: "Corporate Announcements",
      url: "/investors/corporate-announcements",
      icon: <TbSpeakerphone />,
    },
    {
      text: "Policies",
      url: "/investors/policies",
      icon: <TbChecklist />,
    },
    {
      text: "Sustainability",
      url: "/investors/sustainability",
      icon: <TbHeartHandshake />,
    },
  ];

  const careersLinks = [
    {
      text: "Why work with us?",
      url: "/careers#work-with-us",
      icon: isMobileScreen ? <LuGlobe /> : <img src={globe} alt="globe" />,
    },
    {
      text: "Vacancies",
      url: "/careers/vacancies",
      icon: isMobileScreen ? (
        <CgScreen />
      ) : (
        <img src={vacancies} alt="vacancies" />
      ),
    },
    {
      text: "Early Careers",
      url: "/careers/early-careers",
      icon: <HiOutlineBriefcase />,
    },
    {
      text: "Join Our Talent Community",
      url: "/careers#explore-careers",
      icon: <LuUsers />,
    },
  ];

  const mediaLinks = [
    {
      text: "Latest News",
      url: "/media#latest-news",
      icon: isMobileScreen ? (
        <TbNews />
      ) : (
        <img src={newspaper} alt="newspaper" />
      ),
    },
    {
      text: "Upcoming Events",
      url: "/media#upcoming-events",
      icon: <TbTimelineEvent />,
    },
    {
      text: "Past Events",
      url: "/media#past-events",
      icon: <TbHistoryToggle />,
    },
  ];

  const contactLinks = [
    {
      text: "General Enquiries",
      url: "/contact",
      icon: <TbUserQuestion />,
    },
    {
      text: "Investors",
      url: "/contact#contacts",
      icon: <TbStack3 />,
    },
    {
      text: "Partnerships",
      url: "/contact#contacts",
      icon: <TbBuildingEstate />,
    },
    {
      text: "Large-Scale Projects",
      url: "/contact#contacts",
      icon: isMobileScreen ? (
        <TbBorderBottomPlus />
      ) : (
        <img src={plus} alt="plus" />
      ),
    },
    {
      text: "Store Locator",
      url: "/contact#store-locator",
      icon: <LuLocateFixed />,
    },
  ];

  const navItems = [
    {
      title: "About",
      links: aboutLinks,
      imageSrc: about,
      readMoreLink: "/about",
    },
    {
      title: "Business Segments",
      links: businessSegmentLinks,
      imageSrc: bus_segments,
      readMoreLink: "/business-segments",
    },
    {
      title: "Resources",
      links: resourcesLinks,
      imageSrc: resources,
      readMoreLink: "/resources",
    },
    {
      title: "Investors",
      links: investorsLinks,
      imageSrc: investors,
      readMoreLink: "/investors",
    },
    {
      title: "Careers",
      links: careersLinks,
      imageSrc: careers,
      readMoreLink: "/careers",
    },
    {
      title: "Media",
      links: mediaLinks,
      imageSrc: media,
      readMoreLink: "/media",
    },
    {
      title: t("Contact"),
      links: contactLinks,
      imageSrc: contact,
      readMoreLink: "/contact",
    },
  ];

  return (
    <>
      <div
        className={`
          nav-bar px-5
          ${isHomePage ? "nav-home" : "nav-other"}
          ${isMobileScreen ? "nav-mobile" : "nav-desktop"}
          ${menuOpen ? "open" : ""}
        `}
      >
        <div className={`nav-top d-flex justify-content-between align-items-center px-3 ${isHomePage ? "home-nav-top" : ""}`}>
          {(!isMobileScreen || (isMobileScreen && !menuOpen)) && (
            <img
              src={
                isMobileScreen
                  ? isHomePage
                    ? capLogoLight
                    : isMediaPage
                    ? capLogoDark
                    : capLogoDark
                  : isHomePage || isMediaPage
                  ? capLogoLight
                  : capLogoDark
              }
              alt="company logo"
              onClick={navigateHome}
              className="company-logo"
            />
          )}

          {isMobileScreen && (
            <div className="menu-icon" onClick={toggleMenu}>
              {menuOpen ? (
                <FaTimes size={24} color="#000" style={{ display: "none" }} />
              ) : (
                <FaBars size={24} color={isHomePage ? "#FFFFFF" : "#000"} />
              )}
            </div>
          )}
        </div>
        <div className={`right-nav ${menuOpen ? "open" : ""}`}>
          {isMobileScreen && menuOpen && (
            <div className="d-flex justify-content-between align-items-center mt-4">
              <img
                src={capLogoDark}
                alt="company logo"
                onClick={navigateHome}
              />
              <FaTimes size={24} color="#000" onClick={toggleMenu} />
            </div>
          )}

          <div
            ref={navbarRef}
            className={`nav-links ${
              isHomePage ? "nav-links-white" : "nav-links-orange"
            }`}
          >
            {navItems.map((navItem, index) => (
              <div className="nav-item" key={index}>
                <div className="nav-item-head">
                  <NavLink
                    to={navItem.readMoreLink}
                    onClick={(e) => {
                      handleNavLinkClick();
                      if (!isMobileScreen) {
                        handleNavEvent(
                          e,
                          navItem.title,
                          navItem.links,
                          navItem.imageSrc,
                          navItem.readMoreLink,
                          index
                        );
                      }
                    }}
                    onMouseEnter={(e) =>
                      !isMobileScreen &&
                      handleNavEvent(
                        e,
                        navItem.title,
                        navItem.links,
                        navItem.imageSrc,
                        navItem.readMoreLink,
                        index
                      )
                    }
                    className="nav-title sen-700"
                  >
                    {navItem.title}
                  </NavLink>
                  {isMobileScreen && (
                    <FaChevronDown
                      onClick={(e) =>
                        handleNavEvent(
                          e,
                          navItem.title,
                          navItem.links,
                          navItem.imageSrc,
                          navItem.readMoreLink,
                          index
                        )
                      }
                      className={`dropdown-icon ${
                        activeDropdown === index ? "active" : ""
                      }`}
                    />
                  )}
                </div>
                {activeDropdown === index && isMobileScreen && (
                  <div className="dropdown-content gap-3">
                    {navItem.links.map((link, linkIndex) => (
                      <NavLink
                        key={linkIndex}
                        to={link.url}
                        onClick={handleNavLinkClick}
                        className="dropdown-link"
                      >
                        <span className="icon-style">{link.icon}</span>
                        {link.text}
                      </NavLink>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div
            className={`translate d-flex align-items-center justify-content-center p-3 ${
              isMediaPage
                ? "translate-mixed"
                : isHomePage
                ? "translate-dark"
                : "translate-light"
            }
            ${isMobileScreen ? "translate-mobile" : ""}`}
          >
            <select
              className="sen-700"
              onChange={(e) => changeLanguage(e.target.value)}
              value={i18n.language}
            >
              <option value="en">ENG</option>
              <option value="fr">FR</option>
            </select>
            <SlGlobe
              size={30}
              color={
                isMobileScreen
                  ? "#EE8032"
                  : isHomePage || isMediaPage
                  ? "#FFFFFF"
                  : "#000"
              }
            />
          </div>
        </div>
      </div>

      {isPopupOpen && !isMobileScreen && (
        <NavPopup
          isOpen={isPopupOpen}
          onClose={handleClosePopup}
          title={popupData.title}
          links={popupData.links}
          imageSrc={popupData.imageSrc}
          readMoreLink={popupData.readMoreLink}
          position={popupData.position}
        />
      )}
    </>
  );
};

export default Navigation;