import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from "../services/analytics";

const usePageTrack = () => {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname + location.search);
    }, [location.pathname, location.search]);
};
 
export default usePageTrack;