import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

import "../../styles/popup.css";

import { FaAngleRight } from "react-icons/fa6";

const NavPopup = ({
  isOpen,
  onClose,
  title,
  links,
  imageSrc,
  readMoreLink,
  position,
}) => {
  const popupRef = useRef();

  const handleMouseLeave = () => {
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <motion.div
      className="nav-popup-content"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
      style={{ top: position.top, left: position.left }}
      ref={popupRef}
      onMouseLeave={handleMouseLeave}
    >
      <div className="nav-popup-text p-3">
        <h4 className="sen-700">
          <NavLink to={readMoreLink} onClick={onClose} className="title-link">
          {title}
          </NavLink>
          </h4>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              {link.icon}
              <NavLink to={link.url} onClick={onClose}>
                {link.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{ backgroundImage: `url(${imageSrc})` }}
        className="nav-popup-bg"
      >
        <NavLink to={readMoreLink} onClick={onClose} className="nav-popup-readmore sen-500 p-2">
          Read More
          <FaAngleRight style={{ marginLeft: "10px" }} />
        </NavLink>
      </div>
    </motion.div>
  );
};

export default NavPopup;