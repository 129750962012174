import React from "react";
import { useNavigate } from "react-router-dom";
import capLogo from "../../assets/logos/cap_light.svg";
import { TbBrandLinkedin, TbBrandInstagram } from "react-icons/tb";
import "../../styles/footer.css";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  };

  return (
    <footer>
      <div className="d-flex justify-content-center p-5 cap-logo">
        <img src={capLogo} alt="logo" />
      </div>
      <div className="d-flex text-white p-5 justify-content-between gap-5 footer-content">
        <div>
          <h6 className="mb-5 readex-500">About Us</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/about/our-history")}>Our History</span>
            <span onClick={() => handleNavigate("/about/who-we-are")}>CAP Today</span>
            <span onClick={() => handleNavigate("/about/our-leadership")}>Leadership Team</span>
          </div>
        </div>
        <div className="products">
          <h6 className="mb-5 readex-500">Products</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/business-segments")}>Products by Function</span>
            <span onClick={() => handleNavigate("/business-segments")}>Our Brands</span>
          </div>
        </div>
        <div>
          <h6 className="mb-5 readex-500">Resources</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/resources#colour-guides-and-consultancy")}>Colour Guides & Consultancy</span>
            <span onClick={() => handleNavigate("/resources#paint-calculator")}>Paint Calculator</span>
            <span onClick={() => handleNavigate("/resources")}>Visualizer App</span>
            <span onClick={() => handleNavigate("/resources/painters-academy")}>Painters' Academy</span>
            <span onClick={() => handleNavigate("/resources#loyalty-scheme")}>Loyalty Scheme</span>
            <span onClick={() => handleNavigate("/resources/frequently-asked-questions")}>FAQs</span>
          </div>
        </div>
        <div>
          <h6 className="mb-5 readex-500">Investors</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/investors/annual-reports")}>Annual Reports</span>
            <span onClick={() => handleNavigate("/investors/financial-reports")}>Financial Results</span>
            <span onClick={() => handleNavigate("/investors/corporate-governance")}>Corporate Governance</span>
            <span onClick={() => handleNavigate("/investors/corporate-announcements")}>Corporate Announcements</span>
            <span onClick={() => handleNavigate("/investors/policies")}>Policies</span>
            <span onClick={() => handleNavigate("/investors/sustainability")}>Sustainability</span>
          </div>
        </div>
        <div>
          <h6 className="mb-5 readex-500">Contact Us</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/contact")}>General Enquiries</span>
            <span onClick={() => handleNavigate("/contact")}>Investors</span>
            <span onClick={() => handleNavigate("/contact")}>Partnerships</span>
            <span onClick={() => handleNavigate("/contact")}>Large-Scale Projects</span>
            <span onClick={() => handleNavigate("/contact#store-locator")}>Store Locator</span>
          </div>
        </div>
        <div className="others">
          <h6 className="mb-5 readex-500">Others</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/media")}>News & Events</span>
            <span onClick={() => handleNavigate("/careers")}>Careers</span>
            <span onClick={() => handleNavigate("/careers/vacancies")}>Vacancies</span>
            <span onClick={() => handleNavigate("/careers/early-careers")}>Early Careers</span>
          </div>
        </div>
      </div>
      <div className="extras mt-4 mb-4 d-flex flex-column gap-3 align-items-center">
        <div className="social-media-links d-flex gap-5">
          <a
            href="https://www.linkedin.com/company/chemical-and-allied-products-plc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TbBrandLinkedin/>
          </a>
          <a
            href="https://www.instagram.com/cap_plc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TbBrandInstagram/>
          </a>
        </div>
        <div className="extra-links text-white readex-300 d-flex gap-4">
          <span>© 2024 Chemical and Allied Products PLC.</span>
          <span className="divider">|</span>
          <span onClick={() => handleNavigate("/policies/privacy-policy")}>Privacy Policy</span>
          <span className="divider">|</span>
          <span onClick={() => handleNavigate("/policies/cookie-policy")}>Cookie Policy</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;