import dayjs from'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const getRelativeTimeFromTimestamp = (timestamp) => {
    return dayjs.unix(timestamp).fromNow();
};

export const summarize = (text, limit = 30) => {
    if (text.length <= limit) return text;

    return text.substring(0, limit) + '...';
};

export const convertPagePathToTitle = (pagePath) => {
    return pagePath
        .split("/")
        .map((path) => path.replaceAll("-", " "))
        .map((path) => path.substring(0, 1).toUpperCase() + path.substring(1).toLowerCase())
        .join(" ")
};