import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../services/analytics";

import Popup from "./Popup";
import useCookieNotice from "../hooks/useCookieNotice";

import "../styles/popup.css";

const CookieNotice = () => {
  const cookieNotice = useCookieNotice();
  const navigate = useNavigate();

  const handleNavigation = useCallback((url) => {
    logEvent({
      category: 'Cookie Notice',
      action: 'Navigate to cookie policy',
      label: 'View Cookie Policy',
    });

    navigate(url);
  }, [navigate]);

  const onAcceptButtonClick = useCallback(() => {
    cookieNotice.handleAcceptCookies();

    logEvent({
      category: 'Cookie Notice',
      action: 'Accept Button Click',
      label: 'Accept Cookies',
    });
  }, [cookieNotice]);

  const onCloseButtonClick = useCallback(() => {
    cookieNotice.handleAcceptCookies();

    logEvent({
      category: 'Cookie Notice',
      action: 'Close Button Click',
      label: 'Close Cookie Popup',
    });
  }, [cookieNotice]);

  return (
    <Popup isActive={cookieNotice.showCookieNotice} onClosePopup={onCloseButtonClick}>
      <div className="p-3">
        <h5 className="mb-4">🍪 Cookie settings</h5>
        <p>
          By clicking “Accept Cookies”, you agree to the storing of cookies on
          your device to enhance site navigation, analyze site usage, and assist
          in our marketing efforts. View our{" "}
          <span
            onClick={() => handleNavigation("/policies/cookie-policy")}
            className="text-orange"
            style={{ textDecoration: "underline" }}
          >
            Cookie Policy
          </span>{" "}
          for more information.
        </p>
        <div className="btns d-flex gap-2 mt-3">
          <button onClick={() => handleNavigation("/policies/cookie-policy")} className="w-150">
            Cookie Settings
          </button>
          <button onClick={onAcceptButtonClick} className="button-orange w-150">
            Accept Cookies
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default CookieNotice;